/* App.css */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: url("../public/images/background.png") no-repeat center center fixed;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw; /* Full width of the viewport */
  background-size: cover;
}

.container {
  background: transparent;
  background: url("../public/images/tv.png") no-repeat center center fixed;
  background-size:cover;
  padding: 30px 70px;
  text-align: center;
  max-width: 400px;
  margin-bottom: 70px;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
}

p {
  color: #b8b8c8;
  margin-bottom: 20px;
}

button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 12px 24px;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

button.secondary {
  background-color: #2196f3;
}

button.secondary:hover {
  background-color: #1e87e5;
}

footer {
  margin-top: 20px;
}

footer a {
  color: #b8b8c8;
  margin: 0 8px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #ffffff;
}

.secondary {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.secondary.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.customer-info-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.customer-info-container h1 {
  margin-bottom: 10px;

}

.customer-info-container p {
  margin-bottom: 20px;
  color: #666;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.input-group input:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.submit {
  width: 100%;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit:hover {
  background-color: #45a049;
}
